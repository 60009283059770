const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_txt">다시</div>
      <div className="footer_txt">이용약관</div>
      <div className="footer_txt">개인정보처리방침</div>
    </div>
  );
};

export default Footer;
